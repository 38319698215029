import React from 'react';
import Checkbox from '../components/checkbox/checkbox';

export const CheckboxSet = ({ list, name }) => {
  return (
    list &&
    list.map(cond => (
      <Checkbox
        key={cond.InternalValue}
        value={cond.InternalValue}
        name={name}
        displayvalue={cond.DisplayValue}
        id={cond.InternalValue}
      />
    ))
  );
};
