import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import { pinkFont } from '../../constants/theme';

export const ConditionsModalDialog = styled(Dialog)`
  z-index: 9;
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - 136px);
  width: 100% !important;
  min-height: calc(100vh - 136px);
  max-width: 920px;
  background-color: transparent !important;
  padding: 0 17px 0 0 !important;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 108px !important;
  margin-bottom: 28px !important;
  // @media (min-width: 1200px) {
  //   max-width: 1170px;
  // }
  // @media (min-width: 992px) and (max-width: 1199px) {
  //   max-width: 800px;
  // }
  @media (min-width: 576px) and (max-width: 991px) {
    padding: 0 9px !important;
  }
  @media (max-width: 575px) {
    padding: 8px 16px !important;
    margin-top: 0 !important;
  }
  @media (max-width: 992px) and (orientation: landscape){
    height: max-content !important;
    margin: 20px 0 !important;
  }
`;
export const ContentModal = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 16px !important;
  position: relative;
  width: 100%;
  .conatinerConditions {
    margin-bottom: 15px;
  }
  .modalFooter {
    display: flex;
    padding: 0 32px 40px;
    width: 100%;
    margin: 10px 0 16px;
    justify-content: space-between;
    align-items: center;
    .right {
      .buttonOk {
        &:hover {
          text-decoration: underline;
        }
      }
      .clearBtn {
        p {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .clearBtn{    
    font-weight: 700;
    padding: 6px 12px 6px 0;    
    background: none;
    border: none;
    p{
      color:#00a0af;
      font-size: 16px;
      line-height: 24px;
      padding:0;
      &:before{
        background: url(/icons/two-line-gray.png) center center no-repeat;
        width: 22px;
        height: 22px;
        content: " ";
        display: inline-block;
        vertical-align:middle;
        position:relative;
        top:-2px;
      }
    }
    
  }
  .right{
    span{
      color:${pinkFont}
      padding: 7px 6px;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .buttonOk{
    color: white;
    background-color: ${pinkFont};
    padding: 6px 46px;
    border: 1px solid ${pinkFont}
    margin-left: 16px;
    font-size: 16px;
    line-height: 24px;    
    float: right;
    &:hover{
    background-color: #c11831;
    }
    &:focus{
      background-color: #c11831;
    }
  }
  .conditionTitle {
    text-align: center;
    width: calc(100% - 72px);
    margin-left: 15px;
    padding: 0 15px;
    display: flex;
    color: ${pinkFont};
    justify-content: center;
    h5 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
    }
    margin-bottom: 18px;
  }
  .healthy-boxes {
    p {
      font-weight: bolder;
      font-size: 16px;
      line-height: 24px;
    }
    .healthy-options {
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-wrap: wrap-reverse;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .radio-btn {
        label {
          font-size: 16px;
          margin-right: 16px;
        }
        .check {
          width: 24px;
        }
      }
    }
    input[type='checkBox']:checked ~ .check {
      width: 24px;
    }
  }
  .mainContainer{
    width: auto;
  }
  .containerOptions {
    display: flex;
    flex-wrap: wrap;
    .radio-btn {
      label {        
        -webkit-transition: none;
        font-size: 14px;
        padding: 0 3px;
        color: #544f40;
        &:hover {
          background-color: ${pinkFont};
          color: white;
        }
      }
    }
    .genderClass {
      .padding-correction {
        margin: 0;
        line-height: 18px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  //TABLET AND MOBILE
  @media (max-width:992px){
    .buttonOk{
      padding:6px 12px;
    }
    
  
    

  }

  //MOBILE
  @media (max-width: 575px){
    .right{      
      text-align: right;
      span{
        padding-right: 0;
      }
      .clearBtn{
        width: max-content;
        padding-right: 0;
      }
    }
    .healthy-boxes{
      .healthy-options{
        flex-direction: column-reverse;
      }
    }
    margin-bottom: 8px;
  }
  @media all and (hover:none){
    .containerOptions {     
      .radio-btn {
        label {              
          &:hover {
            background-color: white;
            color: #544f40;
          }
        }
      }     
    }
  }
  
`;

export const Button = styled.button`
  position: relative;
  width: 100%;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 0 36px 0 10px;
  p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #808080;
  }
  .arrowRight {
    display: block;
    position: absolute;
    right: 4px;
    top: 0;
    width: 32px;
    height: 32px;
    background-color: #f8f8f8;
    color: #bbb;
    text-align: center;
    background: url(/icons/select-arrow.png) no-repeat center center;
  }
`;
export const ButtonCriteria = styled.button`
  color:${pinkFont}
  background: transparent;
  border:none;
  font-size: 16px;
  line-height: 19.2px;
  text-align: left;
  &:focus {
    outline: none;
  }
  padding: 0;

`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: ${pinkFont};
  border: none;
  font-weight: normal;
  font-size: 24px;
  line-height: 22px;
  position: absolute;
  padding: 10px;
  top: 16px;
  right: 16px;
  &:focus {
    outline: none;
  }
  img {
    line-height: 22px;
    widht: 22px;
    height: 22px;
  }
  z-index: 5;
`;
