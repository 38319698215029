import styled from 'styled-components';
import { UncontrolledPopover } from 'reactstrap';
import { pinkFont } from '../../constants/theme';

export const Popover = styled(UncontrolledPopover)`
  .popover {
    z-index: 25;
    opacity: 1;
    max-width: 400px;
    border: 0;
  }
  .popover.show {
  }
  -webkit-transition: none;
  transition: none;
  .popover-inner {
    max-width: 400px;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 10px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    h2 {
      color: #544f40;
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
    }
    p {
      color: #544f40;
      font-size: 12px;
      line-height: 19px;
      margin-top: 10px;
    }
    strong {
      color: #544f40;
      font-size: 12px;
      line-height: 19px;
      font-weight: bold;
    }
  }
  .arrow {
    display: none;
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    .popover {
      width: 85%;
    }
  }
  @media (max-width: 570px) {
    .popover-inner {
      max-width: 90vw !important;
    }
  }
`;

export const CheckBoxStyle = styled.div`
  .radio-btn {
    color: #333333;
    font-size: 21px;
    line-height: 29px;
    display: inline-block;
    position: relative;
    :hover {
      text-decoration: underline;
    }
    input[type='checkbox'] {
      position: absolute;
      visibility: hidden;
      :hover {
        text-decoration: underline;
      }
    }
    label {
      display: block;
      position: relative;
      white-space: wrap;
      font-size: 16px;
      margin: 0 0 8px 2px;
      padding: 0;
      color: #212529;
      line-height: 20px;
      margin-top: 4px;
      z-index: 2;
      cursor: pointer;
      -webkit-transition: all 0.25s linear;
    }
    .check {
      background-image: url(/icons/checkbox-empty.png);
      background-position-x: 0;
      background-repeat: no-repeat;
      background-size: 14px;
      background-position-y: 2px;
      height: 16px;
      margin-top: 5px;
      width: 16px;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
    }
    .check::before {
      display: block;
      position: absolute;
      content: '';
      // border-radius: 100%;
      height: 10px;
      width: 10px;
      top: 5px;
      left: 5px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
    input[type='checkBox']:checked ~ .check {
      background-image: url(/icons/checkbox-fill.png);
      background-position-x: 0;
      background-repeat: no-repeat;
      background-size: 14px;
      background-position-y: 2px;
      height: 16px;
      width: 16px;
      top: 0;
      left: 0;
      z-index: 1;
    }
    input[type='checkBox']:checked ~ .radio-label {
      color: #0dff92;
    }
  }
  .genderClass {
    display: flex;
    justify-content: left;
  }
  .col-age {
    padding-left: 0;
  }
  .padding-correction {
    padding: 0 20px 0 35px;
  }
  .modalTitle {
    label {
      &:hover {
        background-color: ${pinkFont};
        color: white;
        text-decoration: underline;
      }
      color: ${pinkFont};
      font-size: 18px;
      font-weight: 700;
      -webkit-transition: none;
      width: max-content;
      margin: 0;
    }
    .check {
      display: none;
    }
    input[type='checkBox']:checked ~ .padding-correction {
      background-color: ${pinkFont};
      color: white;
    }
    &.radio-btn {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid ${pinkFont};
      &:hover {
        color: #e31c39;
      }
    }
  }
  .modalOptions {
    display: flex;
    label {
      &:hover {
        background-color: ${pinkFont};
        color: white;
      }
    }
    .check {
      display: none;
    }
    input[type='checkBox']:checked ~ .padding-correction {
      background-color: ${pinkFont};
      color: white;
    }
  }
  @media all and (hover: none) {
    .containerOptions {
      .radio-btn{
        label{
          &:hover{
            background-color: white;
            color: #544f40;
            text-decoration: underline;
          }
        }
      }
    }
    .modalOptions,
    .modalTitle,
    .containerOption {
      label {
        &:hover {
          background-color: white;
          color: ${pinkFont};
        }
      }
    }
    
  }
`;
