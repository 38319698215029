/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Label } from 'reactstrap';
import { CheckBoxStyle } from './checkbox.css';

const Checkbox = props => (
  <Field name={props.name}>
    {({ field, form }) => {
      const {
        value,
        displayvalue: displayValue,
        name,
        special,
        condchildren: condChildren,
        parent,
        setHashCallback,
        modalTitle = false,
        modalOptions = false,
      } = props;
      let idSuffix = Math.floor(Math.random(400) * 10000);
      return (
        <CheckBoxStyle className={'mainContainer'}>
          <div>
            <Label
              className={
                modalTitle
                  ? 'radio-btn genderClass modalTitle'
                  : modalOptions
                  ? 'radio-btn genderClass modalOptions'
                  : 'radio-btn genderClass'
              }
            >
              <input
                type="checkbox"
                {...props}
                checked={field.value && field.value.includes(value)}
                onChange={() => {
                  if (field.value && field.value.includes(value)) {
                    let nextValue = field.value.filter(
                      oldVal => oldVal !== value
                    );
                    if (field.value.includes(parent)) {
                      nextValue = nextValue.filter(oldVal => oldVal !== parent);
                    }
                    if (condChildren) {
                      condChildren.forEach(child => {
                        if (field.value.includes(child)) {
                          nextValue = nextValue.filter(
                            oldVal => oldVal !== child
                          );
                        }
                      });
                    }
                    form.setFieldValue(name, nextValue);
                  } else {
                    let nextValue = field.value.concat(value);
                    if (condChildren) {
                      condChildren.forEach(child => {
                        if (!field.value.includes(child)) {
                          nextValue = nextValue.concat(child);
                        }
                      });
                    }
                    form.setFieldValue(name, nextValue);
                  }
                  if (name.includes('HasCTGovResult')) {
                    let nextValue = form.values.documents.concat(
                      'ATTACHTYPE_FULL_CTR_SUMMARY'
                    );
                    form.setFieldValue('documents', nextValue);
                  }
                  if (
                    name.includes('HasCTGovResult') &&
                    field.value.length === 1
                  ) {
                    let nextValue = form.values.documents.filter(
                      oldVal => oldVal !== 'ATTACHTYPE_FULL_CTR_SUMMARY'
                    );
                    form.setFieldValue('documents', nextValue);
                  }
                  setHashCallback ? setHashCallback() : null;
                }}
                id={displayValue + idSuffix}
              />
              <div hidden={special} className={'check'} tabIndex={0}>
                <div className={'inside'} />
              </div>
              {
                <Label
                  className={' padding-correction'}
                  for={displayValue + idSuffix}
                  // eslint-disable-next-line react/prop-types
                  {...(props.hasTabIndex ? { tabIndex: 0 } : undefined)}
                >
                  {displayValue}
                </Label>
              }
            </Label>
          </div>
        </CheckBoxStyle>
      );
    }}
  </Field>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  displayvalue: PropTypes.string,
  special: PropTypes.bool,
  condchildren: PropTypes.array,
  parent: PropTypes.any,
  setHashCallback: PropTypes.func,
  modalTitle: PropTypes.bool,
  modalOptions: PropTypes.bool,
};

export default Checkbox;
