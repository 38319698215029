import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Close,
  ConditionsModalDialog,
  Button,
  ContentModal,
} from './modal.css';
import VisuallyHidden from '@reach/visually-hidden';
import '@reach/dialog/styles.css';
// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
const Modal = ({
  children,
  showModal,
  hideModal,
  openedModal,
  conditionsList,
  conditionVal,
}) => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    disableScrolling(openedModal);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  function onKeyDown({ key }) {
    if (key === 'Escape') {
      openedModal && hideModal();
    }
  }

  function disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      hideModal();
    }
  }

  return (
    <>
      <Button
        className={'modal-button select-dropdown'}
        type="button"
        onClick={() => {
          showModal();
        }}
      >
        <p>
          {conditionsList.length > 0
            ? conditionVal
            : 'Select Medical Conditions'}
        </p>
        <span className="arrowRight"></span>
      </Button>

      <ConditionsModalDialog isOpen={openedModal}>
        <ContentModal>
          <Close
            onClick={() => {
              hideModal();
            }}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <img src="/icons/icon-modal-close.png" alt="close-icon" />
          </Close>
          {children}
        </ContentModal>
      </ConditionsModalDialog>
    </>
  );
};
export default Modal;

Modal.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  conditionsList: PropTypes.array,
  conditionVal: PropTypes.string,
  openedModal: PropTypes.bool,
};
