export const getSelectedConditions = (fullListOfConditions, conditions) => {
  if (conditions && conditions.length > 0) {
    return `${conditions.map(cond1 => {
      return (
        fullListOfConditions
          .filter(cond2 => {
            if (cond2.InternalValue === cond1) {
              return cond2;
            }
          })
          .map(cond3 => {
            return cond3.DisplayValue;
          })
          .join(', ') +
        fullListOfConditions
          .filter(cond4 => {
            if (
              cond4.Children.filter(cond5 => {
                if (cond5.InternalValue === cond1) {
                  return cond5;
                }
              })
                .map(cond7 => {
                  return cond7.InternalValue;
                })
                .toString() === cond1
            ) {
              return cond4;
            }
          })
          .map(cond9 => {
            return cond9.Children.filter(cond10 => {
              if (cond10.InternalValue === cond1) {
                return cond10;
              }
            })
              .map(cond11 => {
                return cond11.DisplayValue;
              })
              .join(', ');
          })
      );
    })}`;
  } else {
    return '';
  }
};
