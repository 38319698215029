export const formInitialValues = {
  Conditions: [],
  AgeRanges: [],
  Gender: [],
  Phases: [],
  HealthyVolunteer: [],
  SearchTerm: '',
  StudyTypes: '',
  Collaborators: '',
  PatientLevelSharing: [],
  Status: [],
  AttachmentTypes: [],
  country: '',
  zip: '',
  Latitude: '',
  Longitude: '',
};
