import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import Checkbox from '../checkbox/checkbox';

const ModalOptions = ({ conditions }) => {
  return (
    <>
      <Col md={6}>
        {conditions.map((cond, index) => {
          if (index < conditions.length / 2) {
            return (
              <div className={'conatinerConditions'} key={cond.InternalValue}>
                <Checkbox
                  key={cond.InternalValue}
                  value={cond.InternalValue}
                  name="Conditions"
                  displayvalue={cond.DisplayValue}
                  id={cond.InternalValue}
                  modalTitle={true}
                  hasTabIndex={true}
                />
                <div className={'containerOptions'}>
                  {cond.Children.map(cond => (
                    <Checkbox
                      key={cond.InternalValue}
                      value={cond.InternalValue}
                      name="Conditions"
                      displayvalue={cond.DisplayValue}
                      id={cond.InternalValue}
                      className={'Conditions'}
                      modalOptions={true}
                      hasTabIndex={true}
                    />
                  ))}
                </div>
              </div>
            );
          }
        })}
      </Col>
      <Col md={6}>
        {conditions.map((cond, index) => {
          if (index > conditions.length / 2) {
            return (
              <div className={'conatinerConditions'} key={cond.InternalValue}>
                <Checkbox
                  key={cond.InternalValue}
                  value={cond.InternalValue}
                  name="Conditions"
                  displayvalue={cond.DisplayValue}
                  id={cond.InternalValue}
                  modalTitle={true}
                  hasTabIndex={true}
                />
                <div className={'containerOptions'}>
                  {cond.Children.map(cond => (
                    <Checkbox
                      key={cond.InternalValue}
                      value={cond.InternalValue}
                      name="Conditions"
                      displayvalue={cond.DisplayValue}
                      id={cond.InternalValue}
                      className={'Conditions'}
                      modalOptions={true}
                      hasTabIndex={true}
                    />
                  ))}
                </div>
              </div>
            );
          }
        })}
      </Col>
    </>
  );
};

export default ModalOptions;

ModalOptions.propTypes = {
  conditions: PropTypes.array,
};
